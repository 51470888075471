import axios from 'axios';
import { decode } from 'base-64';
import { jwtDecode } from 'jwt-decode';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Navbar
} from 'react-bootstrap';
import './App.css';

function App() {

  const [user, setUser] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [type, setType] = useState('');  // Estado para armazenar o tipo de token

  // Função para extrair parâmetros do hash da URL
  const getHashParams = () => {
    console.log(window.location.hash);
    const hash = window.location.hash.substring(1); // Remove o '#' do início
    const params = {};
    hash.split('&').forEach(part => {
      const [key, value] = part.split('=');
      params[key] = decodeURIComponent(value);
    });
    return params;
  };

  const resetPasswordSupabaseAPI = async () => {
    const req = await axios.put(`https://iculaiwhmqfdwcceeuhv.supabase.co/auth/v1/user`,
      {
        'password': password,
      },
      {
        headers: {
          Authorization: 'Bearer ' + access_token,
          apikey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImljdWxhaXdobXFmZHdjY2VldWh2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg2MzI3ODEsImV4cCI6MjAzNDIwODc4MX0.6nFIO48RK-GXNyI0zm-gUvbWtnXMpgI0UqU_5zzwAPs'
        },
      }

    );
    if (req.status === 200) {
      alert('Senha alterada com sucesso!');
    } else {
      alert('Erro ao alterar senha!');
    }


  }

  const urlParams = new URLSearchParams(window.location.search);
  const emailBase64 = urlParams.get('token');  // v1 token
  const hashParams = getHashParams();
  const access_token = hashParams['access_token'];  // v2 access_token

  const handleSendEmail = async () => {

    if (type === 'v1') {
      const data = {
        usuario: user.toLocaleLowerCase(),
        senha: md5(confirmPassword).toUpperCase()
      }

      const response = await axios.post('https://aws.gestaodevalidades.com.br/validades/usuarios/senha/atualizar', data);

      if (response.status === 200) {
        alert('Senha alterada com sucesso!');
      } else {
        alert('Erro ao alterar senha!');
      }
    } else
      if (type === 'v2') {

        resetPasswordSupabaseAPI()


      }
  };

  useEffect(() => {
    if (emailBase64) {
      // Se o token v1 está presente, decodifica o token base64
      try {
        const decoded = decode(emailBase64);
        setUser(decoded);
        setType('v1');  // Define tipo como v1
      } catch (error) {
        console.error('Erro ao decodificar token base64', error);
      }
    } else if (access_token) {
      // Se o access_token v2 está presente, decodifica o token JWT
      try {
        const decodedToken = jwtDecode(access_token);
        console.log(decodedToken);
        setUser(decodedToken.email);
        setUserId(decodedToken.sub); // Supõe que o email está no token
        setType('v2');  // Define tipo como v2
      } catch (error) {
        console.error('Erro ao decodificar access_token JWT', error);
      }
    }
  }, [emailBase64, access_token]);

  return (
    <div className="App">
      <Navbar bg="light">
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Navbar.Brand>
            <img
              alt=""
              src="https://beta.gestaodevalidades.com.br/img/logo/logo.png"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <div style={{ marginTop: '50px', marginBottom: '50px' }}>
          <h4>Alteração de Senha ({type})</h4>
        </div>
        <div style={{ marginTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              if (password && confirmPassword) {
                if (password.length >= 6) {
                  if (password === confirmPassword) {
                    handleSendEmail();
                  } else {
                    alert('Senhas diferentes!');
                  }
                } else {
                  alert('Senha deve ter no mínimo 6 caracteres!');
                }
              } else {
                alert('Preencha todos os campos!');
              }
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Usuário</Form.Label>
              <Form.Control type="email" disabled value={user} autoComplete="username" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control type="password" autoFocus placeholder="Nova Senha" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Redigite a Nova Senha</Form.Label>
              <Form.Control type="password" placeholder="Redigite a Nova Senha" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} autoComplete="new-password" />
            </Form.Group>

            <Button style={{ backgroundColor: 'rgba(217,141,71,1)', color: 'white', border: 'none', marginTop: '40px' }} size="lg" type="submit">
              {!loading ? 'Confirmar Alteração de Senha' : 'Carregando...'}
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default App;
